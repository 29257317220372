import { getMyQuiz } from '../services/quiz/getMyQuiz';

export function updatePageTitle(pageTitle) {
  try {
    if (typeof pageTitle !== 'string') {
      throw new Error('Page title must be a string');
    }

    document.title = `${document.title} - ${pageTitle}`;
  } catch (error) {
    // Handle the error gracefully
    console.error('Error updating page title:', error);
  }
}

export async function convertUtcToLocal(utcTimestamp) {
  console.log("convertUtcToLocal" + utcTimestamp);

  if (utcTimestamp === null || utcTimestamp === undefined) {
    return ""
  }
  // Create a Date object from the UTC timestamp
  var correctDate = await correctTimestampFormat(utcTimestamp);
  const utcDate = new Date(correctDate);


  // Convert the UTC date to local date and time
  const localDate = utcDate.toLocaleString();

  if (localDate === "Invalid Date") {
    return localDate + "(" + utcTimestamp + ")";
  }

  return localDate;
}
// Helper function to get the numeric month index from a month name
async function getMonthIndex(month) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return months.indexOf(month);
}

async function correctTimestampFormat(timestamp) {
  // Split the timestamp into date and time parts
  let parts = timestamp.split('T');
  let datePart = parts[0];

  // Extract hours, minutes, seconds, and milliseconds using regex
  let timeMatch = parts[1].match(/(\d+):(\d+):(\d+):(\d+)Z/);

  if (!timeMatch) return timestamp; // If pattern doesn't match, return original timestamp

  let hours = String(timeMatch[1]).padStart(2, '0');
  let minutes = String(timeMatch[2]).padStart(2, '0');
  let seconds = String(timeMatch[3]).padStart(2, '0');
  let millis = timeMatch[4];

  // Construct the corrected timestamp
  return `${datePart}T${hours}:${minutes}:${seconds}.${millis}Z`;
}

//ONLY USE IF ABSOLUTLY REQUIRED
export function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export async function calculateGradeByUnit() {

  var quizByStudent = await getMyQuiz();

  let unitSummary = {};

  quizByStudent.forEach(item => {
    if(item.hasQuiz){
    let unitId = item.UnitId;
    let grade = item.Grade === "Not Taken" ? 0 : parseInt(item.Grade, 10);
    // console.log("grade" + item.grade);

    if (!unitSummary[unitId]) {
      unitSummary[unitId] = { totalQuizzes: 0, totalGrade: 0 };
    }

    unitSummary[unitId].totalQuizzes += 1;
    unitSummary[unitId].totalGrade += grade;
    }
  
  });
  

  let finalSummary = Object.keys(unitSummary).map(unitId => {
    const { totalQuizzes, totalGrade } = unitSummary[unitId];
    const averageQuizGrade = totalQuizzes > 0 ? (totalGrade / totalQuizzes) : 0;
    
    // console.log("averageQuizGrade" + averageQuizGrade);
    // console.log("totalGrade" + totalGrade);
    // console.log("totalQuizzes" + totalQuizzes);

    
    return {
      unitId: unitId,
      totalQuizzes: totalQuizzes,
      averageQuizGrade: `${averageQuizGrade.toFixed(2)}%`
    };

   
    // for(const quiz in  quizByStudent){

    // }

  });

  // console.log("quizByStudent: " + JSON.stringify(quizByStudent));
  // console.log("finalSummary: " + JSON.stringify(finalSummary));

  return finalSummary;
}

export async function findNameLessonById(id, lessonsArray) {
  const lessonObject = lessonsArray.find(item => item.id === id);
  return lessonObject ? lessonObject.lesson : null; // Return only the lesson string
}

export async function findNameStructureById(id, lessonsArray) {
  const lessonObject = lessonsArray.find(item => item.id === id);
  return lessonObject ? lessonObject.name : null; // Return only the lesson string
}
import {getHeaders} from '../../utls/headerGenerator';

export async function getStructureAssociation(id, session){

    const options = { 
        method: 'GET', 
        headers: new Headers(await getHeaders()), 
        mode: 'cors',
        credentials: 'omit'
      };
    
      const endpoint = process.env.REACT_APP_BFF + '/getStructureAssociation/' + id;
    
      try {
        const response = await fetch(endpoint, options);
    
        // If the response isn't OK, throw an error to handle below
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
    
        const data = await response.json();
    
        // If the endpoint returns nothing (e.g., empty or null), return null
        if (!data) {
          return null;
        }
    
        return data;
    
      } catch (error) {
        console.error('Error fetching structure association:', error);
        // Return an object with error code and message
        return {
          errorCode: 'FETCH_ERROR',
          message: error.message || 'An error occurred while fetching data.'
        };
      }

    // return true;
}
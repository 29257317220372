import {getHeaders} from '../../utls/headerGenerator';

export async function updateStructureAssociation(payload){
  console.log( "updateStructureAssociation start")

    const options = { 
        method: 'POST', 
        headers: new Headers(await getHeaders()), 
        mode: 'cors',
        credentials: 'omit',
        body: JSON.stringify(payload)
      };
    
      const endpoint = process.env.REACT_APP_BFF + '/updateAssociation';
    
      try {
        const response = await fetch(endpoint, options);
    
        // If the response isn't OK, throw an error to handle below
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        console.log( "updateStructureAssociation" + response.status)


        // If the endpoint returns nothing (e.g., empty or null), return null
        if (response.status !== 200) {
          return false;
        }
        if (response.status === 200) {
          return true;
        }
    
    
      } catch (error) {
        // console.error('Error updating structure association:', error);
        // Return an object with error code and message


        return {
          errorCode: 'FETCH_ERROR',
          message: error.message || 'An error occurred while updating data.'
        };
      }

    // return true;
}
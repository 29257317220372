import React, { useState, useEffect, useContext } from "react";
import FormSelect from 'react-bootstrap/FormSelect'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'
import { getStructureAssociation } from '../../../../services/ar-management/getStructureAssociation';
import { findNameLessonById } from '../../../../utls/lib';

async function replaceLessonIds(dataObject, lessonsArray) {
    const updatedLessonsList = await dataObject.lessonsList.map(async (lessonId) => {
        console.log("ARRAY CHECK replaceLessonIds" + "id");
        const lessonName = await findNameLessonById(lessonId, lessonsArray);
        return {
            id: lessonId,
            lesson: lessonName
        };
    });

    console.log("ARRAY CHECK replaceLessonIds" + "id" + updatedLessonsList);


    return {
        ...dataObject,
        lessonsList: updatedLessonsList
    };
}

function ArViewComponent(props) {
    const [selectedDiagrams, setSelectedDiagrams] = useState([]);
    const [configDetails, setConfigDetails] = useState(null);
    const [assoicationDetails, setAssocaitionDetails] = useState(null);
    const [resultBanner, setResultBanner] = useState('');


    const handleDiagramChange = (event) => {
        setSelectedDiagrams(Array.from(event.target.selectedOptions, option => option.value));
    };

    const handleLoad = async () => {
        setAssocaitionDetails(null);
        setResultBanner('');
        const item = await props.diagrams.find(entry => entry.id === selectedDiagrams[0]);

        setConfigDetails(item)

        let assoicationDetailsd = await getStructureAssociation(item.id);

        if (assoicationDetailsd && assoicationDetailsd.errorCode !== 'FETCH_ERROR') {
            // console.log("props lessons" + JSON.stringify(props.lessons))
            let promiseArray = assoicationDetailsd.lessonsList.map(async (lessonId) => {
                console.log("ARRAY CHECK replaceLessonIds id:", lessonId);
                const lessonName = await findNameLessonById(lessonId, props.lessons);
                return { id: lessonId, lesson: lessonName };
            });

            let lessonDetails = await Promise.all(promiseArray);
            assoicationDetailsd.lessonsList = lessonDetails
            console.log("ARRAY CHECK assoicationDetailsd" + JSON.stringify(lessonDetails));
            setAssocaitionDetails(assoicationDetailsd);
        }

        if (!assoicationDetailsd) {
            //If Successfull
            setResultBanner(() => {
                return (
                    <Alert key="danger" variant="danger">
                        No Association Found, please create.
                    </Alert>
                )
            })
        }

        if (assoicationDetailsd.errorCode === 'FETCH_ERROR') {
            //If Successfull
            setResultBanner(() => {
                return (
                    <Alert key="danger" variant="danger">
                        Error reaching the server. Please try again.
                    </Alert>
                )
            })
        }


    }
    useEffect(async () => {

        console.log("LESSONS" + JSON.stringify(props.lessons))
        // console.log("schoolListDetails " + JSON.stringify(schoolListDetails));
        //    await setSchools(schoolListDetails);

    }, [assoicationDetails]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div style={{ width: '40%', height: "200px", padding: "10px", border: "1px solid #ccc", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
                    <h6>Select an AR Structure</h6>
                    <hr />
                    <FormSelect
                        value={selectedDiagrams}
                        onChange={handleDiagramChange}
                    >
                        {props.diagrams.map(diagram => (
                            <option style={{ fontSize: "14px" }} key={diagram.id} value={diagram.id}>
                                {diagram.name}
                            </option>
                        ))}
                    </FormSelect>

                    <div style={{ textAlign: 'right', paddingRight: "20px", paddingBottom: "20px" }}>
                        <Button onClick={handleLoad} style={{ marginTop: '20px' }}>
                            Load
                        </Button>
                    </div>
                </div>

                {configDetails && (
                    <div style={{
                        width: '40%',
                        minHeight: "200px",
                        padding: "15px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        // backgroundColor: "#f9f9f9",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}>
                        <h6 style={{ marginBottom: "10px", fontWeight: "bold", textAlign: "center" }}>Current Config</h6>
                        <hr />
                        <div style={{ textAlign: "left", padding: "10px", lineHeight: "1.8" }}>
                            <p><strong>Name:</strong> {configDetails.name}</p>
                            {resultBanner}
                            <div>
                                <p><strong>Lessons:</strong></p>
                                <ul style={{
                                    marginLeft: "20px",
                                    listStyleType: "disc",
                                    paddingInlineStart: "20px",
                                }}>
                                    {assoicationDetails && assoicationDetails.lessonsList &&
                                        assoicationDetails.lessonsList.map(document => (
                                            <li>
                                                {document.lesson} - ({document.id})
                                            </li>
                                        ))}
                                </ul>
                            </div>

                            <div>
                                <p><strong>Memberships:</strong></p>
                                <ul style={{
                                    marginLeft: "20px",
                                    listStyleType: "disc",
                                    paddingInlineStart: "20px",
                                }}>
                                    {/* <li>{assoicationDetails.membershipList}</li> */}
                                    {assoicationDetails && assoicationDetails.membershipLst &&
                                        assoicationDetails.membershipLst.map(document => (
                                            <li>
                                                {document}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <hr />
                            <div>
                                Updated By: {assoicationDetails?.updatedBy}
                            </div>
                            <div>
                                Updated On:
                                {assoicationDetails && assoicationDetails.lastUpdateDate &&
                                    (() => {
                                        const { _seconds, _nanoseconds } = assoicationDetails.lastUpdateDate;
                                        const date = new Date(_seconds * 1000 + _nanoseconds / 1000000);
                                        return date.toLocaleString(); // Or use .toLocaleDateString(), etc.
                                    })()
                                }
                            </div>
                            <div>
                                Created By: {assoicationDetails?.createdBy}
                            </div>
                            <div>
                                Created On:
                                {assoicationDetails && assoicationDetails.createdDate &&
                                    (() => {
                                        const { _seconds, _nanoseconds } = assoicationDetails.createdDate;
                                        const date = new Date(_seconds * 1000 + _nanoseconds / 1000000);
                                        return date.toLocaleString(); // Or use .toLocaleDateString(), etc.
                                    })()
                                }
                            </div>
                        </div>

                    </div>
                )}

            </div>
        </>
    )
}

export default ArViewComponent;
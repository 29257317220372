import React, { useState, useEffect, useContext } from "react";
import Accordion from 'react-bootstrap/Accordion'
import ArManagementLessonUpdateComponent from './ar-update-lesson-component';
import ArViewComponent from './ar-view-component';
import { getAllStructuresService } from '../../../../services/ar-management/getAllStructures';
import { getAllLessonsService } from '../../../../services/ar-management/getAllLessons';
import { getAllMemberships } from '../../../../services/getAllMemberships';





function ArManagementMainComponent() {
    const [diagrams, setDiagrams] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [memberships, setMemberships] = useState([]);
    const [selectedDiagrams, setSelectedDiagrams] = useState([]);
    const [selectedLessons, setSelectedLessons] = useState([]);
    const [selectedMemberships, setSelectedMemberships] = useState([]);
    const [lessonSearchTerm, setLessonSearchTerm] = useState('');
    const [membershipSearchTerm, setMembershipSearchTerm] = useState('');
    const [resultBanner, setResultBanner] = useState('');
    const [TESTPAYLOAD, setPayload] = useState({});

    useEffect(() => {
        const fetchDiagrams = async () => {
            if (diagrams.length === 0) { // Only fetch if diagrams is empty
                const diagramlist = await getAllStructuresService();
                setDiagrams(diagramlist);
            }
        };

        const fetchLessons = async () => {
            const lessonList = await getAllLessonsService();
            await setLessons(lessonList);
        };

        const fetchMemberships = async () => {
            const membershipsList = await getAllMemberships();
            await setMemberships(membershipsList);
        };

        fetchDiagrams();
        fetchLessons();
        fetchMemberships();
    }, [diagrams]);
    return (
        <>
            <div align="left">
                <h3>Select Tool from the below Options</h3>
            </div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>1. Update Lesson and AR Assignment</Accordion.Header>
                    <Accordion.Body>
                        <ArManagementLessonUpdateComponent diagrams={diagrams} lessons={lessons} memberships={memberships} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>2. Review AR Config</Accordion.Header>
                    <Accordion.Body>
                        <ArViewComponent diagrams={diagrams} lessons={lessons} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default ArManagementMainComponent;
